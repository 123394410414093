<template>
    <div class="board-container">
        <div role="table" class="board-table board-table--notice">
            <div role="rowgroup">
                <div role="row">
                    <div role="columnheader" class="num">번호</div>
                    <div role="columnheader" class="title">제목</div>
                    <div role="columnheader" class="writer">작성자</div>
                    <div role="columnheader" class="answer">답변</div>
                    <div role="columnheader" class="date">작성일</div>
                </div>
            </div>
            <div role="rowgroup">
                <div v-for="(board, index) in boards" :key="index" role="row">
                    <div role="cell" class="num">{{ (page - 1) * limit + parseInt(index) + 1 }}</div>
                    <div role="cell"><div class="title"><a :href="`?_board=${board._id}`">{{ board.subject }}</a></div></div>
                    <div role="cell" class="writer">{{ board.writer.name.substr(0, 1) }}**</div>
                    <div role="cell" class="answer">
                        <span v-if="board.reply">답변완료</span>
                        <span v-else>대기</span>
                    </div>
                    <div role="cell" class="date"><span class="date">{{ board.createDate.toDate() }}</span></div>
                </div>
            </div>
        </div>
        <pagination-component v-model="page" :count="count" @input="$emit('input', page)"></pagination-component>
        <div class="board-buttons">
            <a v-if="accessToken" class="button button--primary" @click="$emit('write')"><span>글쓰기</span></a>
        </div>
    </div>
</template>

<script>
import PaginationComponent from '@/components/client/pagination-component.vue';
export default{
    components: {
        PaginationComponent
    },
    props: {
        boards: { type: Array, default: [] },
        value: { type: Number, default: 1 },
        count: { type: Number, default: 0 },
        limit: { type: Number, default: 0 }
    },
    data: () => {
        return {
            page: 1
        };
    },
    mounted(){
        this.init();
    },
    methods: {
        init(){
            this.page = this.value;
        }
    },
    computed: {
        accessToken(){
            return this.$store.state.accessToken;
        }
    }
}
</script>
