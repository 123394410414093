var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.board ? _c('div', {
    staticClass: "board-container"
  }, [_c('div', {
    staticClass: "board-table board-table--input",
    attrs: {
      "role": "table"
    }
  }, [_c('div', {
    attrs: {
      "role": "rowgroup"
    }
  }, [_vm.$route.query._board ? _c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    attrs: {
      "role": "columnheader"
    }
  }, [_vm._v("작성자")]), _c('div', {
    attrs: {
      "role": "cell"
    }
  }, [_c('div', {
    staticClass: "form-input"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.board.writer.name,
      expression: "board.writer.name"
    }],
    staticClass: "input",
    attrs: {
      "type": "text",
      "readonly": ""
    },
    domProps: {
      "value": _vm.board.writer.name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.board.writer, "name", $event.target.value);
      }
    }
  })])])]) : _vm._e(), _vm.$route.query._board ? _c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    attrs: {
      "role": "columnheader"
    }
  }, [_vm._v("이메일")]), _c('div', {
    attrs: {
      "role": "cell"
    }
  }, [_c('div', {
    staticClass: "form-input"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.board.writer.email,
      expression: "board.writer.email"
    }],
    staticClass: "input",
    attrs: {
      "type": "text",
      "readonly": ""
    },
    domProps: {
      "value": _vm.board.writer.email
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.board.writer, "email", $event.target.value);
      }
    }
  })])])]) : _vm._e(), _vm.$route.query._board ? _c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    attrs: {
      "role": "columnheader"
    }
  }, [_vm._v("작성일")]), _c('div', {
    attrs: {
      "role": "cell"
    }
  }, [_c('div', {
    staticClass: "form-input"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.board.createDate,
      expression: "board.createDate"
    }],
    staticClass: "input",
    attrs: {
      "type": "text",
      "readonly": ""
    },
    domProps: {
      "value": _vm.board.createDate
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.board, "createDate", $event.target.value);
      }
    }
  })])])]) : _vm._e(), _c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    attrs: {
      "role": "columnheader"
    }
  }, [_vm._v("제목")]), _c('div', {
    attrs: {
      "role": "cell"
    }
  }, [_c('div', {
    staticClass: "form-input"
  }, [false ? _c('select', {
    staticClass: "select",
    attrs: {
      "name": "",
      "id": ""
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("카테고리")])]) : _vm._e(), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.board.subject,
      expression: "board.subject"
    }],
    staticClass: "input",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.board.subject
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.board, "subject", $event.target.value);
      }
    }
  })])])]), false ? _c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    attrs: {
      "role": "columnheader"
    }
  }, [_vm._v("라디오/체크박스")]), _vm._m(0)]) : _vm._e(), _c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    attrs: {
      "role": "cell"
    }
  }, [_c('naver-smarteditor', {
    model: {
      value: _vm.board.content,
      callback: function ($$v) {
        _vm.$set(_vm.board, "content", $$v);
      },
      expression: "board.content"
    }
  })], 1)]), _vm._l(_vm.files, function (item, index) {
    return false ? _c('div', {
      attrs: {
        "role": "row"
      }
    }, [index == 0 ? _c('div', {
      attrs: {
        "role": "columnheader"
      }
    }, [_vm._v("썸네일 이미지")]) : _c('div', {
      attrs: {
        "role": "columnheader"
      }
    }, [_vm._v("첨부파일 " + _vm._s(index))]), _c('div', {
      attrs: {
        "role": "cell"
      }
    }, [_c('div', {
      staticClass: "file-wrap"
    }, [_c('label', {
      staticClass: "file",
      attrs: {
        "for": 'upfile' + index
      }
    }, [_c('input', {
      staticClass: "input",
      attrs: {
        "id": 'upfile' + index,
        "type": "file"
      },
      on: {
        "change": function ($event) {
          return _vm.fileChange(index);
        }
      }
    }), _c('span', {
      staticClass: "text"
    }, [_c('span', [_vm._v(_vm._s(item.name))])]), _c('span', {
      staticClass: "button button--primary"
    }, [_vm._v("파일선택")])])])])]) : _vm._e();
  })], 2)]), _c('div', {
    staticClass: "board-buttons board-buttons--right-center"
  }, [_c('div', {
    staticClass: "right"
  }, [_c('a', {
    staticClass: "button button--border",
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_c('span', [_vm._v("취소")])]), _c('a', {
    staticClass: "button button--primary",
    on: {
      "click": function ($event) {
        _vm.$emit('input', _vm.board);
        _vm.$emit('save');
      }
    }
  }, [_c('span', [_vm._v("확인")])])])])]) : _vm._e();

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "role": "cell"
    }
  }, [_c('div', {
    staticClass: "radio-wrap"
  }, [_c('label', {
    staticClass: "radio"
  }, [_c('input', {
    staticClass: "input",
    attrs: {
      "type": "radio",
      "name": "radio1",
      "value": ""
    }
  }), _c('i', {
    staticClass: "icon"
  }), _c('span', {
    staticClass: "text"
  }, [_vm._v("라디오1")])]), _c('label', {
    staticClass: "radio"
  }, [_c('input', {
    staticClass: "input",
    attrs: {
      "type": "radio",
      "name": "radio1",
      "value": ""
    }
  }), _c('i', {
    staticClass: "icon"
  }), _c('span', {
    staticClass: "text"
  }, [_vm._v("라디오2")])])]), _c('div', {
    staticClass: "check-wrap"
  }, [_c('label', {
    staticClass: "check"
  }, [_c('input', {
    staticClass: "input",
    attrs: {
      "type": "checkbox",
      "name": "check1",
      "value": ""
    }
  }), _c('i', {
    staticClass: "icon"
  }), _c('span', {
    staticClass: "text"
  }, [_vm._v("체크박스1")])]), _c('label', {
    staticClass: "check"
  }, [_c('input', {
    staticClass: "input",
    attrs: {
      "type": "checkbox",
      "name": "check1",
      "value": ""
    }
  }), _c('i', {
    staticClass: "icon"
  }), _c('span', {
    staticClass: "text"
  }, [_vm._v("체크박스2")])])])]);

}]

export { render, staticRenderFns }