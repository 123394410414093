<template>
    <div v-if="board" class="board-container">
        <div role="table" class="board-table board-table--input">
            <div role="rowgroup">
                <div v-if="$route.query._board" role="row">
                    <div role="columnheader">작성자</div>
                    <div role="cell">
                        <div class="form-input">
                            <input v-model="board.writer.name" class="input" type="text" readonly>
                        </div>
                    </div>
                </div>
                <div v-if="$route.query._board" role="row">
                    <div role="columnheader">이메일</div>
                    <div role="cell">
                        <div class="form-input">
                            <input v-model="board.writer.email" class="input" type="text" readonly>
                        </div>
                    </div>
                </div>
                <div v-if="$route.query._board" role="row">
                    <div role="columnheader">작성일</div>
                    <div role="cell">
                        <div class="form-input">
                            <input v-model="board.createDate" class="input" type="text" readonly>
                        </div>
                    </div>
                </div>
                <div role="row">
                    <div role="columnheader">제목</div>
                    <div role="cell">
                        <div class="form-input">
                            <select v-if="false" class="select" name="" id="">
                                <option value="">카테고리</option>
                            </select>
                            <input v-model="board.subject" class="input" type="text">
                        </div>
                    </div>
                </div>
                <div v-if="false" role="row">
                    <div role="columnheader">라디오/체크박스</div>
                    <div role="cell">
                        <div class="radio-wrap">
                            <label class="radio">
                                <input type="radio" name="radio1" value="" class="input">
                                <i class="icon"></i>
                                <span class="text">라디오1</span>
                            </label>
                            <label class="radio">
                                <input type="radio" name="radio1" value="" class="input">
                                <i class="icon"></i>
                                <span class="text">라디오2</span>
                            </label>
                        </div>
                        <div class="check-wrap">
                            <label class="check">
                                <input type="checkbox" name="check1" value="" class="input">
                                <i class="icon"></i>
                                <span class="text">체크박스1</span>
                            </label>
                            <label class="check">
                                <input type="checkbox" name="check1" value="" class="input">
                                <i class="icon"></i>
                                <span class="text">체크박스2</span>
                            </label>
                        </div>
                    </div>
                </div>
                <div role="row">
                    <div role="cell">
                        <naver-smarteditor v-model="board.content"></naver-smarteditor>
                    </div>
                </div>
                <div v-if="false" role="row" v-for="(item, index) in files">
                    <div v-if="index == 0" role="columnheader">썸네일 이미지</div>
                    <div v-else role="columnheader">첨부파일 {{index}}</div>
                    <div role="cell">
                        <div class="file-wrap">
                            <label class="file" :for="'upfile' + index">
                                <input :id="'upfile' + index" class="input" type="file" @change="fileChange(index)">
                                <span class="text"><span>{{item.name}}</span></span>
                                <span class="button button--primary">파일선택</span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 버튼 모바일에서 가운데 정렬 -->
        <div class="board-buttons board-buttons--right-center">
            <!-- <div class="left">
                <a class="button button--border" href=""><span>수정</span></a>
                <a class="button button--border" href=""><span>삭제</span></a>
                <a class="button button--border" href=""><span>답글</span></a>
            </div> -->
            <div class="right">
                <a class="button button--border" @click="$router.go(-1)"><span>취소</span></a>
                <a class="button button--primary" @click="$emit('input', board); $emit('save')"><span>확인</span></a>
            </div>
        </div>
    </div>
</template>

<script>
import NaverSmarteditor from '@/components/plugins/naver/naver-smarteditor.vue';
export default{
	components: {
        NaverSmarteditor
	},
    props: {
        value: { type: Object, default: {} }
    },
    data: function() {
        return{
            board: null,

            files: [{name : ""},{name : ""},{name : ""}],
            fileName: ''
        }
    },
	created() {
	},
	mounted() {
		this.init();
	},
	methods : {
        fileChange(index){
            this.files[index].name = event.target.files[0].name;
        },
		init : function() {
            this.board = this.value;
		}
	},
}
</script>
