<template>
    <div class="pagination">
        <a class="pagination__backward" @click="input(1)"></a>
        <a class="pagination__prev" @click="input(page - 1)"></a>
        <a v-for="i in array" :class="{ 'current': i == 0 }" @click="input(page + i)">{{ page + i}}</a>
        <a class="pagination__next" @click="input(page + 1)"></a>
        <a class="pagination__forward" @click="input(count)"></a>
    </div>
</template>

<script>
export default{
    props: {
        count: { type: Number, default: 0 },
        value:  { type: Number, default: 1 },
    },
    data: function(){
        return {
            page: 0
        };
    },
    created: function(){
        this.page = this.value;
    },
    methods: {
        input: function(page){
            if(this.enabled(page)){
                this.page = page;
                this.$emit("input", page);
                this.$emit("change", page);

                window.scrollTop = 0;
            }
        },
        enabled: function(page){
            if(page < 1) return false;
            else if(this.count < page) return false;
            else return true;
        }
    },
    computed: {
        array(){
            var array = [];
            for(var i=0; i<5; i++){
                if(5 <= array.length) break;
                if(i == 0 && this.enabled(this.page + i)) array.push(i);
                else {
                    if(this.enabled(this.page - i)) array.splice(0,0,i*-1);
                    if(this.enabled(this.page + i)) array.splice(array.length, 0, i);
                }
            }
            return array;
        }
    },
    watch: {
        value: function(){
            this.page = this.value;
        }
    }
}
</script>
