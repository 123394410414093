var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "board-container"
  }, [_c('div', {
    staticClass: "board-table board-table--notice",
    attrs: {
      "role": "table"
    }
  }, [_vm._m(0), _c('div', {
    attrs: {
      "role": "rowgroup"
    }
  }, _vm._l(_vm.boards, function (board, index) {
    return _c('div', {
      key: index,
      attrs: {
        "role": "row"
      }
    }, [_c('div', {
      staticClass: "num",
      attrs: {
        "role": "cell"
      }
    }, [_vm._v(_vm._s((_vm.page - 1) * _vm.limit + parseInt(index) + 1))]), _c('div', {
      attrs: {
        "role": "cell"
      }
    }, [_c('div', {
      staticClass: "title"
    }, [_c('a', {
      attrs: {
        "href": `?_board=${board._id}`
      }
    }, [_vm._v(_vm._s(board.subject))])])]), _c('div', {
      staticClass: "writer",
      attrs: {
        "role": "cell"
      }
    }, [_vm._v(_vm._s(board.writer.name.substr(0, 1)) + "**")]), _c('div', {
      staticClass: "answer",
      attrs: {
        "role": "cell"
      }
    }, [board.reply ? _c('span', [_vm._v("답변완료")]) : _c('span', [_vm._v("대기")])]), _c('div', {
      staticClass: "date",
      attrs: {
        "role": "cell"
      }
    }, [_c('span', {
      staticClass: "date"
    }, [_vm._v(_vm._s(board.createDate.toDate()))])])]);
  }), 0)]), _c('pagination-component', {
    attrs: {
      "count": _vm.count
    },
    on: {
      "input": function ($event) {
        return _vm.$emit('input', _vm.page);
      }
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  }), _c('div', {
    staticClass: "board-buttons"
  }, [_vm.accessToken ? _c('a', {
    staticClass: "button button--primary",
    on: {
      "click": function ($event) {
        return _vm.$emit('write');
      }
    }
  }, [_c('span', [_vm._v("글쓰기")])]) : _vm._e()])], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "role": "rowgroup"
    }
  }, [_c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    staticClass: "num",
    attrs: {
      "role": "columnheader"
    }
  }, [_vm._v("번호")]), _c('div', {
    staticClass: "title",
    attrs: {
      "role": "columnheader"
    }
  }, [_vm._v("제목")]), _c('div', {
    staticClass: "writer",
    attrs: {
      "role": "columnheader"
    }
  }, [_vm._v("작성자")]), _c('div', {
    staticClass: "answer",
    attrs: {
      "role": "columnheader"
    }
  }, [_vm._v("답변")]), _c('div', {
    staticClass: "date",
    attrs: {
      "role": "columnheader"
    }
  }, [_vm._v("작성일")])])]);

}]

export { render, staticRenderFns }