<template>
    <div v-if="board" class="board-container">
        <div role="table" class="board-table board-table--view">
            <div role="rowgroup">
                <div role="row">
                    <div role="columnheader">
                        {{ board.subject }}
                    </div>
                </div>
                <div role="row">
                    <div role="cell">
                        <ul class="board-view-data">
                            <li>작성자 : {{ board.writer.name.substr(0, 1) }}**</li>
                            <li>작성일 : {{ board.createDate.toDateTime() }}</li>
                            <li>조회수 : {{ board.views }}</li>
                        </ul>
                    </div>
                </div>
                <div v-if="false" role="row">
                    <div role="cell">
                        <div class="board-view-file">
                            <span class="head">
                                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 409 513.6" style="enable-background:new 0 0 409 513.6;" xml:space="preserve">
                                    <path d="M41.5,452.8v-353c0-11,9-20,20-20h60v-19c0-11,9-20,20-20c11,0,20,9,20,20v141c0,11-9,20-20,20c-11,0-20-9-20-20v-82h-40v82c0,33.1,26.9,60,60,60c33.1,0,60-26.9,60-60v-122h145.9c11,0,20,9,20,20v353c0,11-9,20-20,20H61.5C50.5,472.8,41.5,463.8,41.5,452.8M85.3,39.8H61.5c-33.1,0-60,26.9-60,60v353c0,33.1,26.9,60,60,60h285.9c33.1,0,60-26.9,60-60v-353c0-33.1-26.9-60-60-60H197.7c-8.5-22.8-30.5-39-56.2-39S93.9,17,85.3,39.8"/>
                                </svg>
                                첨부파일 :
                            </span>
                            <ul class="lists">
                                <li><a href="">파일제목_01.jpg,</a></li>
                                <li><a href="">파일제목_02.jpg,</a></li>
                                <li><a href="">파일제목_03.jpg,</a></li>
                                <li><a href="">파일제목_04.jpg,</a></li>
                                <li><a href="">파일제목_05.jpg</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div v-if="board.content" role="row">
                    <div role="cell">
                        <div class="board-content">
                            <div v-html="board.content"></div>
                        </div>
                    </div>
                </div>
                <div v-if="board.reply" role="row">
                    <div role="cell">
                        <div class="board-content">
                            <div v-html="board.reply"></div>
                        </div>
                    </div>
                </div>
                <div v-if="(board.relativeProducts || []).filter(product => product._id).length" role="row">
                    <div role="cell">
                        <h1 class="title">관련제품</h1>
                        <ul class="board-product-lists">
                            <li v-for="product in board.relativeProducts.filter(product => product._id)" class="board-product-list">
                                <a class="board-product" :href="`/collection/${product.paperCollection.code}?code=${product.code}`">
                                    <div class="board-product__thumb">
                                        <span class="board-product__link">
                                            <img class="board-product__thumbnail" :src="product.thumb" alt="">
                                        </span>
                                    </div>
                                    <div class="board-product__info">
                                        <span class="board-product__text">{{ product.paperCollection.name }}</span>
                                        <span class="board-product__title">{{ product.name }} {{ product.nameEn }}</span>
                                        <span class="board-product__text">[{{ product.code }}]</span>
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="false" role="table" class="board-table board-table--preview">
            <div role="rowgroup">
                <div role="row">
                    <div role="columnheader">
                        이전글
                    </div>
                    <div role="cell">
                        <div class="title"><a href="">이전 게시글 제목입니다.</a></div>
                    </div>
                </div>
                <div role="row">
                    <div role="columnheader">
                        다음글
                    </div>
                    <div role="cell">
                        <div class="title"><a href="">다음 게시글 제목입니다.</a></div>
                    </div>
                </div>
            </div>
        </div>

        <div class="board-buttons">
            <div class="left">
                <a class="button button--border" href=""><span>리스트</span></a>
            </div>
            <div class="right">
                <a v-if="payload && payload._user == board._user" class="button button--border" @click="$emit('edit')"><span>수정</span></a>
                <!-- <a class="button button--border" href=""><span>답글</span></a> -->
                <a v-if="payload && payload._user == board._user" class="button button--primary" @click="$emit('remove')"><span>삭제</span></a>
                <!-- <a class="button button--border" href=""><span>취소</span></a>
                <a class="button button--primary" href=""><span>확인</span></a> -->
            </div>
        </div>
    </div>
</template>

<script>
export default{
	components: {
	},
    props: {
        board: { type: Object, default: null }
    },
	created() {
	},
	mounted() {
		this.init();
	},
	methods : {
		init : function() {
		}
	},
    computed: {
        payload(){
            return this.$store.state.payload;
        }
    }
}
</script>
