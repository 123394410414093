<template>
    <ul class="mypage-nav">
        <li class="mypage-nav__list">
            <a class="mypage-nav__list-link" :data-toggle="(this.$route.path === '/mypage/cart' ? 'opened' : 'closed')" href="/mypage/cart">
                <span>찜한목록</span>
            </a>
        </li>
        <li class="mypage-nav__list">
            <a class="mypage-nav__list-link" :data-toggle="(this.$route.path === '/mypage/order-list' ? 'opened' : 'closed')" href="/mypage/order-list">
                <!-- <i class="arrow"></i> -->
                <span>샘플신청목록</span>
            </a>
            <!-- <ul class="mypage-nav__level mypage-nav__level--1" :class="{ 'active': -1 < $route.path.indexOf('/mypage/order') }">
                <li><a class="mypage-nav__level-link" :data-toggle="-1 < $route.path.indexOf('/mypage/order-list') ? 'opened' : ''" href="/mypage/order-list"><span>전체주문내역</span></a></li>
                <li v-if="accessToken"><a class="mypage-nav__level-link" :data-toggle="-1 < $route.path.indexOf('/mypage/order-cancel') ? 'opened' : ''" href="/mypage/order-cancel"><span>취소내역</span></a></li>
                <li v-if="accessToken"><a class="mypage-nav__level-link" :data-toggle="-1 < $route.path.indexOf('/mypage/order-exchange') ? 'opened' : ''" href="/mypage/order-exchange"><span>교환내역</span></a></li>
                <li v-if="accessToken"><a class="mypage-nav__level-link" :data-toggle="-1 < $route.path.indexOf('/mypage/order-return') ? 'opened' : ''" href="/mypage/order-return"><span>반품내역</span></a></li>
            </ul> -->
        </li>
        <li v-if="accessToken" class="mypage-nav__list">
            <a class="mypage-nav__list-link" :data-toggle="(this.$route.path === '/mypage/myinfo' ? 'opened' : 'closed')" href="/mypage/myinfo">
                <!-- <i class="arrow"></i> -->
                <span>내정보</span>
            </a>
            <!-- <ul class="mypage-nav__level mypage-nav__level--1" :class="{ 'active': -1 < $route.path.indexOf('/mypage/myinfo') || -1 < $route.path.indexOf('/mypage/leave') }">
                <li><a class="mypage-nav__level-link" :data-toggle="-1 < $route.path.indexOf('/mypage/myinfo') ? 'opened' : ''" href="/mypage/myinfo"><span>회원정보수정</span></a></li>
                <li><a class="mypage-nav__level-link" :data-toggle="-1 < $route.path.indexOf('/mypage/leave') ? 'opened' : ''" href="/mypage/leave"><span>회원탈퇴</span></a></li>
            </ul> -->
        </li>
        <li v-if="accessToken" class="mypage-nav__list">
            <a class="mypage-nav__list-link" :data-toggle="(this.$route.path === '/mypage/leave' ? 'opened' : 'closed')" href="/mypage/leave">
                <!-- <i class="arrow"></i> -->
                <span>회원탈퇴</span>
            </a>
        </li>
    </ul>
</template>

<script>
export default{
    mounted(){
        this.init();
    },
    methods: {
        init(){
    		console.log(this.$route.path);
        }
    },
    computed: {
        accessToken(){
            return this.$store.state.accessToken;
        }
    }
}
</script>
