var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "pagination"
  }, [_c('a', {
    staticClass: "pagination__backward",
    on: {
      "click": function ($event) {
        return _vm.input(1);
      }
    }
  }), _c('a', {
    staticClass: "pagination__prev",
    on: {
      "click": function ($event) {
        return _vm.input(_vm.page - 1);
      }
    }
  }), _vm._l(_vm.array, function (i) {
    return _c('a', {
      class: {
        'current': i == 0
      },
      on: {
        "click": function ($event) {
          return _vm.input(_vm.page + i);
        }
      }
    }, [_vm._v(_vm._s(_vm.page + i))]);
  }), _c('a', {
    staticClass: "pagination__next",
    on: {
      "click": function ($event) {
        return _vm.input(_vm.page + 1);
      }
    }
  }), _c('a', {
    staticClass: "pagination__forward",
    on: {
      "click": function ($event) {
        return _vm.input(_vm.count);
      }
    }
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }